import React, {FormEvent, useState} from "react";
import "./HiringPage.css";
import {sendHiringData} from "../api";

interface FormData {
    name: string;
    email: string;
    linkedin: string;
}


const HiringPage: React.FC = () => {
    const [formData, setFormData] = useState<FormData>({
        name: "",
        email: "",
        linkedin: ""
    });

    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // Handle form submission, e.g., send the data to a server
        sendHiringData(formData).then(r => setSubmitted(true));
        // Reset the form after submission
    };



    return (
        <div className="hiring-page">
            <h1>We're Hiring!</h1>
            <p>Interested in joining our team? Fill out the form below with your details, and we’ll get in touch with you!</p>

            {submitted ? (
                <p>Thank you for applying! We will review your details and get back to you soon.</p>
            ) : (
                <form className="hiring-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Full Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="email">Email Address</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="linkedin">LinkedIn Profile</label>
                        <input
                            type="url"
                            id="linkedin"
                            name="linkedin"
                            value={formData.linkedin}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <button type="submit">Submit Application</button>
                </form>
            )}
        </div>
    );
};

export default HiringPage;