import React from "react";
import ServiceHex from "./ServiceHex/ServiceHex";
import blockchainlogo from "../Images/blockchain-logo.jpg";
import aiConsultinglogo from "../Images/ai-consulting.jpg";

const ServicesSubmenu: React.FC = () => {


    return (
        <ul id="hexGrid">
            <ServiceHex key="1" name="Blockchain" logo={blockchainlogo} url="/services/blockchain" description="Add Blockchain to your business" />
            <ServiceHex key="2" name="AI-Consulting" logo={aiConsultinglogo} url="/services/ai-consulting" description="Learn to harness the power of AI"/>
        </ul>
    );
};

export default ServicesSubmenu;