import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import greylogo from "../aify30Grey.png";
import "./Header.css";
import {checkUserAuthentication} from "../amplify-api";
import logo from "../aify30.png";

const Header: React.FC = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [user, setUser] = useState<any>(null);

    useEffect(() => {
        async function checkAuth() {
            try {
                const currentUser = await checkUserAuthentication();
                setIsAuthenticated(true);
                setUser(currentUser);
            } catch (error) {
                setIsAuthenticated(false);
            }
        }

        checkAuth();
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className="header">
            <div className="logo-section">
                {isAuthenticated ? (<img src={logo} alt="User Icon"/>) : (<img src={greylogo} alt="User Icon"/>)}
                <h1 className="site-name">AIFY</h1>
            </div>
            <div className="subheader">
                {/* Subheader links */}
                <nav className={`nav-links ${menuOpen ? 'open' : ''}`}>
                    <Link to="/">Home</Link>
                    <Link to="/services">Services</Link>
                    <Link to="/jobs">Jobs</Link>
                    <Link to="/contact">Contact Us</Link>
                    <Link to="/contacts">Contacts</Link>
                </nav>
            </div>
            <div className="login-info">
                {isAuthenticated ? (
                    <div className="user-info">
                        <span>{user.name}</span>
                        <Link to="/logout" className="logout-link">Logout</Link>
                    </div>
                ) : (

                    <Link to="/login" className="login-link">Login</Link>
                )}
            </div>
            {/* Hamburger Menu Icon */}
            <div className="hamburger" onClick={toggleMenu}>
                <span className={menuOpen ? 'hamburger-icon open' : 'hamburger-icon'}></span>
            </div>
        </header>
    );
};

export default Header;