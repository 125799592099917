import React, {useEffect, useState} from "react";
import {fetchAiImage} from "../../api";
import {fetchAuthSession} from "aws-amplify/auth";
import "../services.css";

interface AiImageProps {
    imageType: string;
}

const AiImage: React.FC<AiImageProps> = (props) => {


    const [aiImage, setAiImage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {

        const fetchImage = async () => {
            const token = await getJwtToken();
            try {
                const response = await fetchAiImage(token, props.imageType);
                // Convert ArrayBuffer to Base64 string
                const base64String = arrayBufferToBase64(response);
                // Set the Base64 string as the image source
                setAiImage(`data:image/png;base64,${base64String}`);
            } catch (error) {
                setError('Error fetching the image:'+ error);
            } finally {
                setLoading(false);
            }
        };

        fetchImage();

    }, [props.imageType]);

    const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    };


    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;
    return (
                <div className="image-container">
                    {aiImage && <img src={aiImage} alt="illustration" />}
                </div>
    )
};

async function getJwtToken(): Promise<string | undefined> {
    try {
        const session = await fetchAuthSession();
        return  session.tokens?.idToken?.toString()
    } catch (error) {
        console.error('Error retrieving token:', error);
        return undefined;
    }
}

export default AiImage;