import React, {ChangeEvent, Component, FormEvent} from 'react';
import './ContactForm.css';
import {sendContactData} from "../api";

interface FormData {
    name: string;
    email: string;
    message: string;
}

interface State {
    formData: FormData;
    submitted: boolean;
}

class ContactForm extends Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            submitted: false,
            formData: {
                name: '',
                email: '',
                message: ''
            }
        };
    }


    handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: value
            }
        }));
    };

    handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // Handle form submission, e.g., send the data to a server
        console.log('Form submitted:', this.state.formData);
        sendContactData(this.state.formData);
        // Reset the form after submission
        this.setState({
            submitted: true,
            formData: {
                name: '',
                email: '',
                message: ''
            }
        });
    };

    render() {
        const {formData} = this.state;

        return (
            <div className="hiring-page">
                <h1>Feedback</h1>
                <p>Do you have feedback for our team, leave us a message and we’ll get in touch with you!</p>

                {this.state.submitted ? (
                    <p>Thank you for getting in touch! We will review your message and get back to you soon.</p>
                ) : (
                <form className="hiring-form" onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Full Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={this.handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="email">Email Address</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={this.handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="message">Message:</label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={this.handleChange}
                            required
                        />
                    </div>
                    <button type="submit">Submit</button>
                </form>)}
            </div>
        );
    }
}

export default ContactForm;