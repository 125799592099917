import './ServiceHex.css';
import {Link} from "react-router-dom";
import React from "react";

interface ServiceHexProps {
    name: string;
    logo: string;
    url: string;
    description: string;
}

const ServiceHex: React.FC<ServiceHexProps> = ({name, logo, url, description}) => {
    return (
        <li className="hex">
            <div className="hexIn">
                <Link className="hexLink" to={url}>
                    <h1>{name}</h1>
                    <img src={logo} alt={name}/>
                    <p>{description}</p>
                </Link>
            </div>
        </li>
    );
};

export default ServiceHex;