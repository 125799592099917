import {ResourcesConfig} from '@aws-amplify/core';

const resourcesConfig: ResourcesConfig = {
    Auth: {

        Cognito: {
            userPoolClientId: "2vu4e7n7touh8dcourfa99sj4p",
            userPoolId: "us-east-1_K7JHbv9xb",
            loginWith: {
                oauth: {
                    domain: "https://aify.auth.us-east-1.amazoncognito.com",
                    scopes: ["email", "profile", "openid"],
                    redirectSignIn: ["http://localhost:3000/"],
                    redirectSignOut: ["http://localhost:3000/"],
                    responseType: "code"
                }
            }
        }
    }
};

export default resourcesConfig;