import React from 'react';
import {Contacts} from './Contacts';
import './UserTable.css'

interface UserTableProps {
    users: Contacts[];
}

const UserTable: React.FC<UserTableProps> = ({ users }) => {
    return (
        <table className="aify-table">
            <thead>
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Message</th>
            </tr>
            </thead>
            <tbody>
            {users.map(user => (
                <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.message}</td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default UserTable;