import React, {useEffect, useState} from 'react';
import UserTable from "./UserTable";
import {Contacts} from "./Contacts";
import {fetchContactData} from "../api";
import {fetchAuthSession} from 'aws-amplify/auth';

const AdminForm: React.FC = () => {
    const [users, setUsers] = useState<Contacts[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {

        const fetchUsers = async () => {
            const token = await getJwtToken();
            try {
                const data: Contacts[] = await fetchContactData(token);
                setUsers(data);
            } catch (error) {
                setError('Failed to fetch users');
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;
    return (
        <div>
            <h1>User List</h1>
            <UserTable users={users} />
        </div>
    );
}

async function getJwtToken(): Promise<string | undefined> {
    try {
        const session = await fetchAuthSession();
        return session.tokens?.idToken?.toString()
    } catch (error) {
        console.error('Error retrieving token:', error);
        return undefined;
    }
}

export default AdminForm;