import React, {useEffect, useState} from 'react';
import logo from "../Images/aifymedium.png";
import {fetchData} from "../api";
import './home.css';

const Home: React.FC = () => {


    const [clients, setData] = useState<any[]>([]);


    useEffect(() => {
        fetchData().then(data => setData(data));
    }, []);

    return (
        <main>
            <img src={logo} className="App-logo" alt="logo"/>
            <div className="content">
                <h2>Aify</h2>
                {clients?.map(user =>
                    <div key={user}>
                        {user}
                    </div>
                )}
            </div>
        </main>
    )
        ;
};

export default Home;