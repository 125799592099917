import React from "react";
import BlockchainInfo from "./BlockchainInfo";
import AiImage from "../aiconsulting/AiImage";

const Blockchain: React.FC = () => {
    return (
        <div className="service-content">
            <div className="left-side">
                <BlockchainInfo/>
            </div>
            <div className="right-side">
                <AiImage imageType="Blockchain"/>
            </div>
        </div>
    )
};

export default Blockchain;