import React from 'react';
import {signOut} from '@aws-amplify/auth';

const LogoutButton = () => {

    const handleLogout = async () => {
        try {
            await signOut();
        } catch (error) {
            console.log('Error signing out: ', error);
        }
    };

    return (
        <button onClick={handleLogout}>
            Log Out
        </button>
    );
};

export default LogoutButton;