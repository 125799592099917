import React from "react";
import AiConsultingInfo from "./AiConsultingInfo";
import AiImage from "./AiImage";

const AiConsulting: React.FC = () => {
    return (
        <div className="service-content">
            <div className="left-side">
                <AiConsultingInfo/>
            </div>
            <div className="right-side">
                <AiImage imageType="Consulting"/>
            </div>
        </div>
    )
};

export default AiConsulting;