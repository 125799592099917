import React from "react";
import LogoutButton from "../login/Logout";
import './Footer.css'

const Footer: React.FC = () => {
    return (
        <div className="footer">
            <p>Privacy Policy | Terms of Service</p>
            <p>Contact: post@aify.no | Phone: (123) 456-7890</p>
            <p><LogoutButton/></p>
        </div>
    )
};

export default Footer;