import './App.css';
import './amplify-custom.css';
import ContactForm from "./contactForm/ContactForm";
import React from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from "./home/home";
import AdminForm from "./admin/AdminForm";
import Header from "./Header/Header";
import Jobs from "./Jobs/HiringPage";
import Services from "./services/Services";
import {Authenticator} from "@aws-amplify/ui-react";
import {Amplify} from "aws-amplify";
import awsExports from "./aws-exports";
import '@aws-amplify/ui-react/styles.css';
import AiConsulting from "./services/aiconsulting/AiConsulting";
import Blockchain from "./services/blockchain/Blockchain";
import Footer from "./Footer/Footer"; // Import Amplify styles

Amplify.configure(awsExports);

const App: React.FC = () => {

    return (
        <Router>
            <div className="container">
                <Header/>
                <div className="contentPane">
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/contact" element={<ContactForm/>}/>
                        <Route path="/services" element={<Services/>}/>
                        <Route path="/jobs" element={<Jobs/>}/>
                        <Route path="/contacts" element={<Authenticator><AdminForm/></Authenticator>}/>
                        <Route path="/services/ai-consulting" element={<AiConsulting/>}/>
                        <Route path="/services/blockchain" element={<Blockchain/>}/>
                    </Routes>
                </div>
                <Footer/>
            </div>
        </Router>
    );
}

export default App;
