import React, {useEffect, useState} from "react";
import {fetchBlockchainData} from "../../api";
import {fetchAuthSession} from "aws-amplify/auth";
import {AiResponse} from "../AiResponse";
import "../services.css";

const BlockchainInfo: React.FC = () => {
    const [blockchainInfo, setBlockchainInfo] = useState<AiResponse>({latest_developments: []});
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {

        const fetchBlockchainInfo = async () => {
            const token = await getJwtToken();
            try {
                const data: AiResponse = await fetchBlockchainData(token);
                setBlockchainInfo(data);
            } catch (error) {
                setError('Failed to fetch blockchaininfo' + error);
            } finally {
                setLoading(false);
            }
        };

        fetchBlockchainInfo();
    }, []);



    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;
    return (
        <><h2 className="headline">Blockchain as a Service</h2>
            <ul className="item-list">
                {blockchainInfo?.latest_developments.map(details => (
                    <li className="item" key={details.title}>
                        <h3 className="item-title">{details.title}</h3>
                        <p className="item-description">{details.description}</p>
                    </li>
                ))}
            </ul>
        </>
    )
};

async function getJwtToken(): Promise<string | undefined> {
    try {
        const session = await fetchAuthSession();
        return  session.tokens?.idToken?.toString()
    } catch (error) {
        console.error('Error retrieving token:', error);
        return undefined;
    }
}

export default BlockchainInfo;