
const apiUrl = process.env.REACT_APP_API_URL;

export const fetchData = async () => {
    const response = await fetch(`${apiUrl}/api/users/42`);
    return response.json();
};

export const fetchUser = async () => {
    const response = await fetch(`${apiUrl}/api/user`, {
        method: 'GET',
        credentials: 'include', // Ensures cookies are sent with the request
    });
    return response.json();
};

export const sendContactData = async (formData) => {
    const response = await fetch(`${apiUrl}/api/contact`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
        credentials: 'include'
    });
    return response.json();
}

export const sendHiringData = async (formData) => {
    const response = await fetch(`${apiUrl}/api/hiring`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
        credentials: 'include'
    });
    return response.json();
}

export const fetchContactData = async (token) => {
    const response = await fetch(`${apiUrl}/admin/contacts`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            'Content-Type': 'application/json',
        },
        credentials: 'include', // Ensures cookies are sent with the request
    });
    return response.json();
}

export const fetchBlockchainData = async (token) => {
    const response = await fetch(`${apiUrl}/ai/blockchain`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include', // Ensures cookies are sent with the request
    });
    return response.json();
}

export const fetchAiImage = async (token, type) => {
    const response = await fetch(`${apiUrl}/ai/image/`+type, {
        method: 'GET',
        headers: {
            'Content-Type': 'image/png',
        },
        credentials: 'include', // Ensures cookies are sent with the request
    });
    return response.arrayBuffer();
}

export const fetchAiConsultingData = async (token) => {
    const response = await fetch(`${apiUrl}/ai/consulting`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include', // Ensures cookies are sent with the request
    });
    return response.json();
}

