import React from "react";
import "./services.css";
import ServicesSubmenu from "./ServicesSubmenu";

const Services: React.FC = () => {
    return (
        <ServicesSubmenu/>
    )
};

export default Services;